import React from "react";
import Moment from "react-moment";
import { Tooltip } from "antd";
import { formatDateInPDT } from "../utils/helper";
import { Link } from "react-router-dom";

// import moment from "moment";
// const dayjs = require("dayjs");

function BaseTable({
  header,
  data,
  remove,
  edit,
  restore,
  publice,
  view,
  type,
  resend,
  viewStats,
}) {
  const viewStatsAction = (item) => {
    return (
      <span
        className="text-blue-500 hover:text-blue-700 cursor-pointer mr-2"
        onClick={() => viewStats(item?._id)}
      >
        View Stats |
      </span>
    );
  };

  const viewEmailStatus = (item) => {
    const { Email_status, sentAt, scheduled, scheduledAt } = item;
    if (Email_status === "Sent" || scheduled) {
      const statusText = scheduled ? "Scheduled" : "Sent";

      const formattedDateTime = formatDateInPDT(
        scheduled ? scheduledAt : sentAt,
        scheduled
      );

      return `${statusText} at ${formattedDateTime}`;
    } else {
      return item?.Email_status;
    }
  };
  return (
    <>
      {type === "article" ? (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        {header.map((item, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-xs md:text-sm font-bold text-left text-gray-600 uppercase "
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((item, i) => (
                        <tr key={i} className="hover:bg-sky-100">
                          <td className=" max-w-96 px-6 py-3.5 text-sm font-medium text-gray-800 whitespace-nowrap">
                            <p
                              className={`max-w-96 truncate cursor-pointer
                                ${item?.Document_status !== "deleted"}
                                  ? "text-gray-500 hover:text-blue-500 cursor-pointer"
                                  : "text-gray-500"
                              `}
                              onClick={() =>
                                item?.Document_status !== "deleted" &&
                                edit(item?._id)
                              }
                            >
                              {item?.draft?.Title}
                            </p>
                          </td>
                          <td className="px-6 py-2">
                            <p
                              className={` ${
                                item?.Document_status === "deleted"
                                  ? "bg-red-200 text-red-600"
                                  : `${
                                      item?.Document_status === "Published"
                                        ? "bg-[#AFDBF5] text-[#002387]"
                                        : "bg-yellow-200 text-yellow-700"
                                    } `
                              }  rounded-sm px-4 py-0.5 text-[0.8rem] w-28 text-center font-medium whitespace-nowrap capitalize`}
                            >
                              {item?.Document_status}
                            </p>
                          </td>
                          <td className="px-6 py-3.5 text-sm font-medium text-gray-500 whitespace-nowrap">
                            <Moment format="DD-MMM-YYYY">
                              {item?.lastAccessedAt?.time}
                            </Moment>
                          </td>
                          {item?.Document_status === "deleted" ? (
                            <td className="px-6 py-3.5 text-sm font-medium  whitespace-nowrap">
                              <span
                                className="text-gray-500 hover:text-blue-700 cursor-pointer"
                                onClick={() => restore(item?._id)}
                              >
                                Restore
                              </span>
                            </td>
                          ) : (
                            <td className="px-6 py-3.5 text-sm font-medium  whitespace-nowrap">
                              <span
                                className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                onClick={() => edit(item?._id)}
                              >
                                Edit |
                              </span>
                              <span
                                className="text-gray-500 hover:text-blue-700 ml-2 cursor-pointer"
                                onClick={() => remove(item?._id)}
                              >
                                Delete |
                              </span>
                              {item?.Document_status === "Published" ? (
                                <span
                                  className="text-blue-500 hover:text-blue-700 ml-2 cursor-pointer"
                                  onClick={() => view(item?._id)}
                                >
                                  View
                                </span>
                              ) : (
                                <span
                                  className="text-blue-500 hover:text-blue-700 ml-2 cursor-pointer"
                                  onClick={() => publice(item?._id)}
                                >
                                  Publish
                                </span>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : type === "email" ? (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {header.map((item, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-xs md:text-sm font-bold text-left text-gray-500 uppercase "
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((item, i) => (
                        <tr key={i} className="hover:bg-sky-100">
                          <td className="px-6 py-3.5 font-medium">
                            <Tooltip title={item?.Subject}>
                              {item?.Email_status !== "draft" ||
                              item?.Email_status !== "Test" ? (
                                <p className="w-[300px] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500">
                                  {item?.Subject}
                                </p>
                              ) : (
                                <p
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                  onClick={() => edit(item?._id)}
                                >
                                  {item?.Subject}
                                </p>
                              )}
                            </Tooltip>
                          </td>
                          <td className="px-6 py-3.5 text-sm font-medium text-gray-500 capitalize">
                            {viewEmailStatus(item)}
                          </td>
                          <td className="px-6 py-3.5 text-sm font-medium text-gray-500 whitespace-nowrap">
                            <Moment format="DD-MMM-YYYY">
                              {item?.lastAccessedAt?.time}
                            </Moment>
                          </td>
                          {item?.Email_status === "deleted" ? (
                            <td className="px-6 py-3.5 text-sm font-medium  whitespace-nowrap">
                              <span
                                className="text-gray-500 hover:text-blue-700 cursor-pointer"
                                onClick={() => restore(item?._id)}
                              >
                                Restore
                              </span>
                            </td>
                          ) : (
                            <td className="px-6 py-3.5 text-sm font-medium  whitespace-nowrap">
                              {(item?.Email_status === "draft" ||
                                item?.Email_status === "Test") && (
                                <span
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer mr-2"
                                  onClick={() => edit(item?._id)}
                                >
                                  Edit |
                                </span>
                              )}

                              {item?.Email_status === "Sent" ||
                              item?.Email_status === "Test"
                                ? viewStatsAction(item)
                                : null}

                              <span
                                className="text-gray-500 hover:text-blue-700  cursor-pointer"
                                onClick={() => remove(item?._id)}
                              >
                                Delete
                              </span>
                              {item?.Email_status === "Sent" ? (
                                <>
                                  {/* <span
                                                                                        className="text-blue-500 hover:text-blue-700 ml-2 cursor-pointer"
                                                                                        onClick={() => view(item?._id)}
                                                                                    >
                                                                                        View
                                                                                    </span> */}
                                  <span
                                    className="text-blue-500 hover:text-blue-700 ml-2 cursor-pointer"
                                    onClick={() => resend(item?._id)}
                                  >
                                    | Resend
                                  </span>
                                </>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : type === "event" ? (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {header.map((item, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((item, i) => (
                        <tr key={i} className="hover:bg-sky-100">
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            <Link to={`/event/view/${item?.Event_Id}`}>
                              {item?.Event_Name.split(" ")
                                .slice(0, 4)
                                .join(" ")}
                            </Link>
                          </td>
                          <td className="px-6 py-4 font-medium">
                            <Tooltip title={item?.Event_Organizer}>
                              <p className="w-[200px] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500">
                                {item?.Event_Organizer}
                              </p>
                            </Tooltip>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.Event_Start_Date}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.Event_End_Time}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {/*<span
                                                                        className="text-blue-500 hover:text-blue-700 cursor-pointer "
                                                                        onClick={() => remove(item?._id, i)}
                                                                    >
                                                                        Remove
                                                        </span> */}
                            <span className="text-blue-500 hover:text-blue-700 cursor-pointer ml-3">
                              <a href={item?.Event_Website} target="_blank">
                                View
                              </a>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : type === "widget" ? (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {header.map((item, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((item, i) => (
                        <tr key={i} className="hover:bg-sky-100">
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            <p
                              className={
                                item?.Widget_status !== "deleted"
                                  ? "text-gray-500 hover:text-blue-500 cursor-pointer"
                                  : "text-gray-500"
                              }
                              onClick={() =>
                                item?.Widget_status !== "deleted" &&
                                edit(item?._id)
                              }
                            >
                              {item?.Widget_Name}
                            </p>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.Widget_status}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            <Moment format="DD-MMM-YYYY">
                              {item?.lastAccessedAt?.time}
                            </Moment>
                          </td>
                          {item?.Widget_status === "deleted" ? (
                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                              <span
                                className="text-gray-500 hover:text-blue-700 cursor-pointer"
                                onClick={() => restore(item?._id)}
                              >
                                Restore
                              </span>
                            </td>
                          ) : (
                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                              <span
                                className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                onClick={() => edit(item?._id)}
                              >
                                Edit |
                              </span>
                              <span
                                className="text-gray-500 hover:text-blue-700 ml-2 cursor-pointer"
                                onClick={() => remove(item?._id)}
                              >
                                Delete
                              </span>
                              {item?.Widget_status === "Published" ? (
                                <span
                                  className="text-blue-500 hover:text-blue-700 ml-2 cursor-pointer"
                                  onClick={() => view(item?._id)}
                                >
                                  Get Code
                                </span>
                              ) : (
                                <span
                                  className="text-blue-500 hover:text-blue-700 ml-2 cursor-pointer"
                                  onClick={() => publice(item?._id)}
                                >
                                  Publish
                                </span>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : type === "custom" ? (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {header.map((item, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((item, i) => (
                        <tr key={i} className="hover:bg-sky-100">
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {item?.is_deleted ? (
                              <p
                                className="text-sm  text-gray-500"
                                // onClick={() => view(item?._id)}
                              >
                                {item?.Team_Group_Name}
                              </p>
                            ) : (
                              <p
                                className="text-sm font-medium text-blue-500 hover:text-blue-700 cursor-pointer"
                                onClick={() => view(item?._id)}
                              >
                                {item?.Team_Group_Name}
                              </p>
                            )}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.Members_count}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            <Moment format="DD-MMM-YYYY hh-mm:A">
                              {item?.lastAccessedAt?.time}
                            </Moment>
                            {/* {item?.lastAccessedAt?.Time ?? "1 day ago"} */}
                          </td>

                          <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                            {item?.is_deleted ? (
                              <span
                                className="text-blue-500 hover:text-blue-700 cursor-pointer ml-3"
                                onClick={() => restore(item?._id)}
                              >
                                restore
                              </span>
                            ) : (
                              <>
                                <span
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                  onClick={() => edit(item?._id)}
                                >
                                  Edit
                                </span>
                                <span
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer ml-3"
                                  onClick={() => remove(item?._id)}
                                >
                                  Remove
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {header.map((item, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((item, i) => (
                        <tr key={i} className="hover:bg-sky-100">
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            <p className="text-blue-500 hover:text-blue-700 cursor-pointer">
                              {item?.Email}
                            </p>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.FirstName}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.LastName}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.Role}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                            {item?.status}
                          </td>

                          <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                            {item.Role !== "Owner" ? (
                              <>
                                <span
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                  onClick={() => edit(item?._id)}
                                >
                                  Edit
                                </span>
                                <span
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer ml-3"
                                  onClick={() => remove(item?._id)}
                                >
                                  Remove
                                </span>
                              </>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BaseTable;
