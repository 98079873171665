import React, { useState, useEffect, memo, useContext } from "react";
import { Avatar } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getItem, setItem, deleteItem } from "../utils/storage";
import { ToastContainer, toast } from "react-toastify";
import { getReqParamheader } from "../services/apiCall";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { OrgContext } from "../OrgContext";
import CustomButton from "./CustomButton";
import { LogIn } from "lucide-react";

const initial = [
  {
    name: "Create an Organization",
    url: "/create-org",
  },
  {
    name: "Profile Setting",
    url: "/user/setting",
  },
];
function Navbar() {
  const [token, setToken] = useState(false);
  const [card, setCard] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [orgData, setOrg] = useState([]);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});
  const loc = useLocation().pathname;
  const navigate = useNavigate();
  const { organizationId, setOrganizationId } = useContext(OrgContext);

  useEffect(() => {
    let t = CheckAuth();
    const id = getItem("user_id");
    if (t && id) {
      setToken(t);
      setUserId(id);
    }
  }, [getItem("token"), token]);

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, [userId, getItem("user")]);

  useEffect(() => {
    getCardData();
  }, [user]);

  const getCardData = () => {
    // console.log(user)
    let org = user?.Organizationlist || [];

    // Generate 10 random org data objects
    // const randomOrgData = [];
    // for (let i = 0; i < 10; i++) {
    //   const orgData = {
    //     org_id: generateRandomId(), // Function to generate a random org ID
    //     org_name: generateRandomName(), // Function to generate a random org name
    //   };
    //   randomOrgData.push(orgData);
    // }

    // Concatenate the random org data with the existing org array
    // org = [...org, ...randomOrgData];

    setOrg(org);
    setCardData(initial);
    console.log(user);
    console.log("org::::;", org);
  };

  // Function to generate a random org ID
  // const generateRandomId = () => {
  //   // Logic to generate a random ID
  //   const length = 8;
  //   const characters =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let randomId = "";

  //   for (let i = 0; i < length; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     randomId += characters.charAt(randomIndex);
  //   }

  //   return randomId;
  // };

  // Function to generate a random org name
  // const generateRandomName = () => {
  //   // Logic to generate a random name

  //   const names = [
  //     "John",
  //     "Jane",
  //     "Alex",
  //     "Emma",
  //     "Michael",
  //     "Olivia",
  //     "William",
  //     "Sophia",
  //   ];
  //   const randomIndex = Math.floor(Math.random() * names.length);
  //   return names[randomIndex];
  // };

  const getUserData = () => {
    if (userId && userId.length > 0) {
      console.log("user_id:::;", userId);
      getUserById(userId);
    }
  };
  const getUserById = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("response get user by id ", res?.data?.data);
          setItem("user", JSON.stringify(res?.data?.data));
          setItem("currentuser", res?.data?.data?.firstName);
          setUser(res?.data?.data);
          //toast.success('User success ..!')
          // setTimeout(() => {
          //     navigate("/");
          // }, 2000)
        } else {
          console.log("response err", res?.message);
          // toast.error(`${res?.message}`)
          //Couldn't verify user so logout
          logout();
        }
      })
      .catch((err) => {
        console.log("err user by id ", err);
        toast.error("User not identified. Please relogin.");
        //Couldn't verify user so logout
        logout();
      });
  };
  const CheckAuth = () => {
    const session = getItem("token");
    if (session) {
      return true;
    }
    return false;
  };

  const logout = () => {
    deleteItem("token");
    toggleCard();
    CheckAuth();
    setToken(false);
    //Remove all the local items
    deleteItem("user_id");
    deleteItem("currentOrg");
    deleteItem("user");
    deleteItem("org_id");
    deleteItem("currentuser");
    setUserId("");
    navigate("/");
  };
  const toggleCard = () => {
    setCard(!card);
  };
  const handleStore = (item) => {
    setItem("currentOrg", item?.org_name);
    setOrganizationId(item?.orgId);
  };
  return (
    <div className="w-full px-8  py-4 h-[60px] z-10 bg-white">
      <div className="flex justify-between items-center">
        <div>
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/images/smorgborg-logo.png"}
              className="w-[138px]"
            />
          </Link>
        </div>
        {!token ? (
          <CustomButton
            icon={<LogIn size={20} />}
            content={"Login"}
            bgColor={"bg-blue-600 "}
            clickHandler={() => navigate("/login")}
            additionalStyles={"text-white hover:bg-blue-700"}
          />
        ) : (
          <div className="relative" onClick={toggleCard}>
            {user?.profile_photo ? (
              <Avatar
                className="cursor-pointer"
                src={user?.profile_photo}
                alt="profile"
                size="large"
              ></Avatar>
            ) : (
              <div className="bg-blue-200 text-xl font-bold border-2 border-blue-700 text-blue-700 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer">
                <p>{user?.firstName?.split("")[0].toUpperCase() || "U"}</p>
              </div>
            )}

            {card && (
              <div className="absolute top-8 right-1 z-20 w-[250px]">
                <div className="text-white bg-[#001529] my-2 border rounded-md p-2">
                  <div className="border-b pb-2 mb-2 divide-y">
                    <div className="overflow-y-auto max-h-[60vh]">
                      {orgData?.length > 0 &&
                        orgData.map((item, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              toggleCard();
                              handleStore(item);
                            }}
                            className="flex flex-col gap-2"
                          >
                            <Link to={"/manage/" + item?.orgId}>
                              <div className="cursor-pointer hover:bg-gray-700 text-white px-4 py-2 text-sm rounded-sm font-medium">
                                {item?.org_name}
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                  {cardData.map((item, i) => (
                    <div key={i} className="">
                      <Link to={item.url}>
                        <div
                          className="cursor-pointer hover:bg-gray-700 text-white px-4 py-2 font-medium text-sm"
                          onClick={toggleCard}
                        >
                          {item?.name}
                        </div>
                      </Link>
                    </div>
                  ))}
                  <div className="mt-2 border-t border-white">
                    <button
                      className="cursor-pointer mt-2 transition-all hover:bg-red-700 text-white px-4 py-1.5 w-[232px] border-2 border-red-700 rounded-sm text-sm font-medium"
                      onClick={logout}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Navbar);
