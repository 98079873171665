import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import { getItem } from "../utils/storage";
import {
  getReqParamheader,
  postReqParamheader,
  deleteReqParamheader,
  postReqParamheaderFile,
  patchReqParam,
  getReqParam,
} from "../services/apiCall";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
import BaseTabs from "../components/BaseTabs";
import samplefile from "../assests/newContact.xlsx";
import axios from "axios";
import fileDownload from "js-file-download";
import CustomButton from "../components/CustomButton";
import {
  UserRoundPlus,
  Frown,
  Search,
  X,
  Save,
  Trash2,
  List,
  Upload,
  Download,
} from "lucide-react";
import ModalLoader from "../components/ModalLoader";

function ContactHome() {
  const [contact, setContact] = useState([]);
  const [data, setData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCard, setActiveCard] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Mphone: "",
    Wphone: "",
    Company: "",
    JT: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    Country: "",
  });
  const [orgId, setOrg] = useState("");
  const [selectedCustomList, setSelectedCustomList] = useState("");
  const [list, setList] = useState([]);
  const [userId, setUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [file, uploadimg] = useState("");
  const [activeTab, setActiveTab] = useState("General");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [reachedEndOfList, setReachedEndOfList] = useState(false);
  const [fetchingContacts, setFetchingContacts] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const override = {
    display: "inline-block",
    margin: "0 auto",
    borderColor: "blue",
    verticalAlign: "center",
  };

  const LIMIT_PER_PAGE = 200;
  let currentPage = 1;

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId && userId) {
      setLoading(true);
      getContacts(1);
    }
  }, [orgId]);

  const handleActive = async (item) => {
    setLoadingMessage("Loading the contact...");
    setModalLoading(true);
    let id = item?._id;
    await getContactbyId(id);
  };

  const getCustomContact = (toggle) => {
    // setLoading(true)
    let url = `${API_URL_CONSTANT.baseUrl}/customlist_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get custom contact details response', res?.data)
          // setLoading(false)
          let deleted = res?.data?.data.filter(
            (item) => item?.is_deleted !== true
          );
          setList(deleted);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setLoading(false)
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const getContactbyId = async (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/contact_detailsbyid/${id}/${orgId}/${userId}`;
    let res = await getReqParamheader(url);
    if (res) {
      if (res?.status) {
        // console.log('get contact details by id response', res?.data?.data)
        setActiveCard({
          ...activeCard,
          FirstName: res?.data?.data?.FirstName,
          LastName: res?.data?.data?.LastName,
          Email: res?.data?.data?.Email,
          Mphone: res?.data?.data?.Mobile_Phone || "",
          Wphone: res?.data?.data?.Work_Phone || "",
          Company: res?.data?.data?.Company || "",
          JT: res?.data?.data?.Job_Title,
          Address: res?.data?.data?.Address,
          City: res?.data?.data?.City,
          State: res?.data?.data?.State,
          Zip: res?.data?.data?.Zip,
          Country: res?.data?.data?.Country,
          _id: res?.data?.data?._id,
        });
        setModalLoading(false);
        setShowContact(true);
      } else {
        console.log("response err", res?.message);
      }
    } else {
      console.log("response err", res?.message);
      setModalLoading(false);
      toast.error("Unable to open at this time");
    }
  };
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const getContacts = async (offset = 1) => {
    setFetchingContacts(true);
    let limit = LIMIT_PER_PAGE;
    let url = `${API_URL_CONSTANT.baseUrl}/contact_details/${orgId}/${userId}?Limit=${limit}&Offset=${offset}`;
    let res = await getReqParamheader(url);
    if (res?.status) {
      setTotalCount(res?.data?.count);
      let newContacts = res?.data?.data;
      let uniqueArray = getUniqueListBy(newContacts, "Email");
      if (newContacts.length > 0) {
        setContact((contacts) => [...contacts, ...newContacts]);
        getCustomContact(false);
      } else {
        setReachedEndOfList(true);
      }
    } else {
      console.log("response err", res?.message);
    }
    setFetchingContacts(false);
    setLoading(false);
  };

  const loadMoreContacts = () => {
    if (LIMIT_PER_PAGE >= totalCount) {
      setReachedEndOfList(true);
    } else {
      currentPage++;
      let offset = (currentPage - 1) * LIMIT_PER_PAGE + 1;
      searchTerm === "" ? getContacts(offset) : handleSearch(offset);
      setReachedEndOfList(false);
    }
  };

  const ref = useRef(null);

  const options = {
    root: null,
    threshold: [0.75],
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        loadMoreContacts();
      }
    }, options);

    if (loadMore && ref.current) {
      // Ensure ref.current is not null
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [loadMore]);

  useEffect(() => {
    if (contact.length > 0 && !fetchingContacts && !reachedEndOfList) {
      setLoadMore(true);
    } else if (!fetchingContacts && reachedEndOfList) {
      setLoadMore(false);
    }
  }, [contact, reachedEndOfList]);

  const handleCreateContact = () => {
    if (data?.FirstName && data?.LastName && data?.Email) {
      setData({});
      setLoadingMessage("Saving contact...");
      setModalLoading(true);
      addContact(data);
    } else {
      alert("Please fill all field..");
    }
  };

  const handleUpdateContact = () => {
    let update = {
      FirstName: activeCard?.FirstName,
      LastName: activeCard?.LastName,
      Work_Phone: activeCard?.Wphone,
      Mobile_Phone: activeCard?.Mphone,
      Company: activeCard?.Company,
      Job_Title: activeCard?.JT,
      Address: activeCard?.Address,
      City: activeCard?.City,
      State: activeCard?.State,
      Zip: activeCard?.Zip,
      Country: activeCard?.Country,
    };

    let id = activeCard?._id;
    if (activeCard?.FirstName && activeCard?.LastName) {
      setLoadingMessage("Saving changes...");
      setModalLoading(true);
      updateContact(id, update);
    } else {
      alert("Please fill all required details..");
    }
  };

  const updateContact = (id, data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/updatecontact/${id}/${orgId}/${userId}`;
    patchReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          setModalLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
          setModalLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setModalLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleTab = (txt) => {
    setActiveTab(txt);
  };
  const addContact = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/add_a_contact/${orgId}/${userId}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('add contact response', res?.data)
          setShowModal(!showModal);
          setModalLoading(false);
          setLoading(true);
          setContact([]);
          getContacts(1);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setModalLoading(false);
      });
  };

  const handleCustom = () => {
    navigate(`${path}/custom`);
  };

  const remove = (id) => {
    let deleteContactConfirmation = window.confirm(
      "Are you sure you want to delete this contact?"
    );
    if (deleteContactConfirmation) {
      setLoadingMessage("Deleting profile...");
      setModalLoading(true);
      handleRemove(id);
    }
  };

  const handleRemove = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/deletecontact/${id}/${orgId}/${userId}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setShowContact(false);
          setModalLoading(false);
          setContact([]);
          setLoading(true);
          getContacts(1);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
          setModalLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setModalLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (orgId && searchTerm === "") {
        getContacts(1);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearch = () => {
    setLoading(true);
    // let limit = LIMIT_PER_PAGE;
    let url = `${
      API_URL_CONSTANT.baseUrl
    }/searchContactByName/${orgId}/${userId}?q=${encodeURIComponent(
      searchTerm
    )}`;

    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setContact(res?.data?.data);
          setTotalCount(res?.data?.count);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleCreateCustom = () => {
    navigate(`/${path}/custom/create`);
  };
  const handleDownloadSample = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  const handleDownload = () => {
    let filename = "Contact.xlsx";
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/get-exported-contacts/${orgId}/${userId}`;
    getReqParam(url, {
      // responseType: 'blob',
    })
      .then((blob) => {
        setLoading(false);
        // console.log('get export response', blob)
        //fileDownload(res.data, filename)

        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error("Sorry,Unable to export this file right this moment");
        }, 1000);
      });
  };
  const Filehandler = (e) => {
    if (e.target.files.length !== 0) {
      uploadimg({
        picturePreview: URL.createObjectURL(e.target.files[0]),
        pictureAsFile: e.target.files[0],
      });
    }
  };
  const saveImport = () => {
    let data = {};
    if (file?.pictureAsFile) {
      data.files = file?.pictureAsFile;
      uploadContact(data);
    } else {
      alert("please upload file....");
    }
  };
  const uploadContact = (data) => {
    setLoading(true);
    let url = "";
    if (selectedCustomList) {
      // alert(selectedCustomList)
      url = `${API_URL_CONSTANT.baseUrl}/import-contacts/${orgId}/${userId}?customListName=${selectedCustomList}`;
    } else {
      url = `${API_URL_CONSTANT.baseUrl}/import-contacts/${orgId}/${userId}`;
    }

    postReqParamheaderFile(url, data)
      .then((res) => {
        // console.log('save import response', res)
        setShowImport(false);
        toast.success(res?.data?.message);
        setSelectedCustomList("");
        setLoading(true);
        getContacts(1);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 2000);
      });
  };
  const handleSwich = (item, id, cid) => {
    let arr = item?.Members?.filter((item) => item?.memberId === id);
    if (arr?.length > 0) {
      // handleRemoveContact(id)
      handleRemoveContact(item?._id);
      // alert('remove')
    } else {
      handleAddContact(item?._id);
      // alert('add')
    }
  };
  const handleAddContact = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/add_to_customlist/${activeCard?._id}/${orgId}/${userId}/${id}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('add contact in custom response', res?.data)
          setLoading(false);
          getCustomContact(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  const handleRemoveContact = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/remove_from_customlist/${activeCard?._id}/${orgId}/${userId}/${id}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('removecontact in custom response', res?.data)
          setLoading(false);
          getCustomContact(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  const handleCheck = (item, id) => {
    let arr = item?.Members?.filter((item) => item?.memberId === id);
    if (arr?.length > 0) {
      return true;
    }
    return false;
  };

  const handleShowAddContactModal = () => {
    setShowModal(!showModal);
  };

  ///tabs related
  const FieldInput = ({ label, value, onChange, disabled = false }) => (
    <div className="my-3 gap-2 grid grid-cols-4">
      <label className="text-gray-600 col-span-1">{label}:</label>
      <input
        type="text"
        className={`px-2 py-1 w-full border border-gray-400 rounded col-span-3 me-2 ${
          disabled && "opacity-50 cursor-not-allowed"
        }`}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );

  const ContactTab = ({ activeCard, setActiveCard }) => {
    const fields = [
      { label: "Mobile Phone", key: "Mphone" },
      { label: "Work Phone", key: "Wphone" },
      { label: "Company", key: "Company" },
      { label: "Job Title", key: "JT" },
      { label: "Address", key: "Address" },
      { label: "City", key: "City" },
      { label: "State", key: "State" },
      { label: "Zip", key: "Zip" },
      { label: "Country", key: "Country" },
    ];

    return (
      <>
        {fields.map((field) => (
          <FieldInput
            key={field.key}
            label={field.label}
            value={activeCard?.[field.key] || ""}
            onChange={(e) =>
              setActiveCard({ ...activeCard, [field.key]: e.target.value })
            }
          />
        ))}
      </>
    );
  };

  const GeneralTab = ({ activeCard, setActiveCard }) => (
    <>
      <FieldInput label="Email" value={activeCard?.Email || ""} disabled />
      <FieldInput
        label="First Name"
        value={activeCard?.FirstName || ""}
        onChange={(e) =>
          setActiveCard({ ...activeCard, FirstName: e.target.value })
        }
      />
      <FieldInput
        label="Last Name"
        value={activeCard?.LastName || ""}
        onChange={(e) =>
          setActiveCard({ ...activeCard, LastName: e.target.value })
        }
      />
    </>
  );

  const CustomListsTab = ({
    list,
    activeCard,
    handleSwitch,
    handleCheck,
    path,
  }) => (
    <div className="pb-4">
      {list?.length > 0 ? (
        <div>
          <p className="text-sm text-gray-600 pb-2">
            Assign this contact to custom lists:
          </p>
          {list.map((item, i) => (
            <div key={i} className="flex items-center my-1">
              <input
                type="checkbox"
                onChange={(e) =>
                  handleSwitch(item, activeCard?._id, e.target.checked)
                }
                value={item?._id}
                checked={handleCheck(item, activeCard?._id)}
              />
              <p className="text-sm pl-4">{item?.Team_Group_Name}</p>
            </div>
          ))}
          <p className="text-sm text-blue-500 cursor-pointer pt-2">
            <Link to={`${path}/custom/create`}>Create A New Custom List</Link>
          </p>
        </div>
      ) : (
        <div>
          <p className="text-sm text-gray-600">
            You do not have any custom lists. You can create one using the link
            below.
          </p>
          <p className="text-sm text-blue-500 cursor-pointer">
            <Link to={`${path}/custom/create`}>Create A New Custom List</Link>
          </p>
        </div>
      )}
    </div>
  );

  const isNewContactFormIncomplete =
    !data.FirstName || !data.LastName || !data.Email;

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between items-center mb-3">
            <p className="text-sm md:text-lg lg:text-xl font-medium">
              Contacts ({totalCount})
            </p>
            <div className="flex gap-4 items-center">
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Search by name or email"
                  className=" w-[25vw] px-2 py-1 md:px-3 md:py-[7px] text-sm md:text-base placeholder-gray-500 pe-8 md:pe-24 rounded-sm outline-1 focus:outline-blue-500 active:outline-blue-500 transition-all "
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <CustomButton
                  icon={<Search size={18} color="#fff" />}
                  content={"Search"}
                  bgColor={"bg-blue-600 "}
                  clickHandler={handleSearch}
                  additionalStyles={"text-white hover:bg-blue-700"}
                  disabled={searchTerm === ""}
                />
              </div>
              <CustomButton
                icon={<UserRoundPlus size={20} />}
                bgColor={"bg-blue-600"}
                content={"Add a Contact"}
                clickHandler={handleShowAddContactModal}
                additionalStyles={"text-white hover:bg-blue-700"}
              />
            </div>
          </div>

          <div className="flex gap-2 my-2">
            <CustomButton
              icon={<List size={20} />}
              bgColor={"bg-blue-100"}
              content={"Custom Lists"}
              clickHandler={handleCustom}
              additionalStyles={"text-blue-700 hover:bg-blue-200"}
            />
            <CustomButton
              icon={<Upload size={20} />}
              bgColor={"bg-blue-100"}
              content={"Export"}
              clickHandler={handleDownload}
              additionalStyles={"text-blue-700 hover:bg-blue-200"}
            />
            <CustomButton
              icon={<Download size={20} />}
              bgColor={"bg-blue-100"}
              content={"Import"}
              clickHandler={() => setShowImport(!showImport)}
              additionalStyles={"text-blue-700 hover:bg-blue-200"}
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
            {contact.length > 0 ? (
              contact.map((item, i) => {
                // Determine styles based on status
                const isUnsubscribed =
                  item?.Status?.toLowerCase() === "unsubscribed";
                const statusStyles = isUnsubscribed
                  ? "bg-red-200 text-red-800"
                  : "bg-green-200 text-green-800";

                return (
                  <div
                    key={i}
                    className={`transition-all rounded cursor-pointer border-2 flex justify-start ${
                      isUnsubscribed
                        ? "hover:border-red-600"
                        : "hover:border-green-600"
                    }`}
                    onClick={() => handleActive(item)}
                  >
                    <div
                      className={`w-[20%] rounded-l ${statusStyles} md:flex justify-center items-center hidden font-bold text-xl`}
                    >
                      {item?.FirstName?.[0]?.toUpperCase() ?? "M"}
                    </div>
                    <div
                      className={`w-full md:w-[80%] rounded flex flex-col p-2 text-gray-800 transition-all bg-white `}
                    >
                      <p className=" truncate font-medium">
                        {item?.FirstName} {item?.LastName}
                      </p>
                      <p className="text-sm truncate">{item?.Email}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w-[60vw] h-[60vh] flex justify-center flex-col items-center gap-8">
                <Frown size={48} color="#6a6868" strokeWidth={1.25} />
                <p className="text-lg text-gray-600 font-medium">
                  No Contacts found
                </p>
              </div>
            )}
          </div>

          {modalLoading && <ModalLoader content={loadingMessage} />}

          {showModal && (
            <Modal setOpenModal={setShowModal} title="Create a New Profile">
              <label className="text-gray-600"> First Name*:</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.FirstName}
                onChange={(e) =>
                  setData({ ...data, FirstName: e.target.value.trim() })
                }
              />
              <label className="text-gray-600"> Last Name*:</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.LastName}
                onChange={(e) =>
                  setData({ ...data, LastName: e.target.value.trim() })
                }
              />
              <label className="text-gray-600"> Email*:</label>
              <input
                type="text"
                required
                className="px-4 py-1 my-2 w-full border lowercase"
                value={data?.Email?.toLowerCase()}
                onChange={(e) =>
                  setData({
                    ...data,
                    Email: e.target.value.trim().toLowerCase(),
                  })
                }
              />

              <button
                className="px-4 py-2 bg-blue-500 rounded-sm my-3 w-full text-white hover:bg-blue-700 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50"
                onClick={handleCreateContact}
                disabled={isNewContactFormIncomplete}
              >
                {" "}
                Create a profile{" "}
              </button>
            </Modal>
          )}
          {showImport && (
            <Modal setOpenModal={setShowImport} title="Import Contact">
              <div className="my-3">
                <p className="text-sm text-gray-600 py-3">
                  {" "}
                  Please follow given sample file format...
                  <span
                    className="text-blue-500 cursor-pointer hover:text-blue-700"
                    onClick={() =>
                      handleDownloadSample(samplefile, "SampleContactList.xlsx")
                    }
                  >
                    Download Sample
                  </span>
                </p>
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={Filehandler}
                  placeholder="upload contact"
                  className="py-3"
                />
                <div className="my-3">
                  <label className="block mb-2">Select Custom List:</label>
                  {list?.length > 0 ? (
                    <div>
                      <select
                        className=" border border-gray-300 text-gray-700 text-sm rounded-sm w-full px-2 py-2 bg-white"
                        onChange={(e) => setSelectedCustomList(e.target.value)}
                      >
                        <option value=""> select</option>
                        {list.map((item, i) => (
                          <option value={item?.Team_Group_Name} key={i}>
                            {" "}
                            {item?.Team_Group_Name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div className="w-[60vw] h-[60vh] flex justify-center flex-col items-center gap-8">
                      <Frown size={48} color="#6a6868" strokeWidth={1.25} />
                      <p className="text-lg text-gray-600 font-medium">
                        No Contacts found
                      </p>
                    </div>
                  )}
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className="px-4 py-2 bg-blue-500 rounded-md  text-white hover:bg-blue-700"
                    onClick={saveImport}
                  >
                    {" "}
                    Save
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {showContact && (
            <Modal
              setOpenModal={setShowContact}
              item={activeCard}
              type="contact"
              remove={remove}
            >
              <div className="my-2">
                <BaseTabs
                  active={activeTab}
                  handleTab={handleTab}
                  tabList={["General", "Contact Info", "Custom Lists"]}
                />
              </div>

              <div className="h-[45vh] overflow-y-auto">
                {activeTab === "General" && (
                  <GeneralTab
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                )}
                {activeTab === "Contact Info" && (
                  <ContactTab
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                )}
                {activeTab === "Custom Lists" && (
                  <CustomListsTab
                    list={list}
                    activeCard={activeCard}
                    handleSwitch={handleSwich}
                    handleCheck={handleCheck}
                    path={path}
                  />
                )}
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <CustomButton
                  icon={<Trash2 size={20} />}
                  bgColor={"bg-red-600"}
                  content={"Delete Profile"}
                  clickHandler={() => remove(activeCard?._id)}
                  additionalStyles={"text-white px-2 py-1 hover:bg-red-700"}
                />

                <CustomButton
                  icon={<Save size={20} />}
                  bgColor={"bg-blue-600"}
                  content={"Save Changes"}
                  clickHandler={handleUpdateContact}
                  additionalStyles={"text-white px-2 py-1 hover:bg-blue-700"}
                />
              </div>
            </Modal>
          )}
          <ToastContainer />
          {loadMore && totalCount > LIMIT_PER_PAGE && (
            <div
              ref={ref}
              className="flex justify-center items-center h-32 loadingtext"
            >
              <p>Loading</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ContactHome;
