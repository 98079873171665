import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Moment from "react-moment";
import Modal from "../../components/Modal";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import {
  postReqParamheaderFile,
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
} from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import PageSnipper from "../../components/PageSnipper";
import { Camera, Trash2, Image, ImageUp, FolderPlus, Save } from "lucide-react";
import CustomButton from "../../components/CustomButton";

const EmptyAlbum = ({ type, title, photoCount, i, remove, path, active }) => {
  return (
    <div className="p-2 rounded-md shadow-lg bg-white flex flex-col gap-1 ">
      <div className="flex justify-between items-center border-b-2 border-gray-300  pb-2">
        <p className="text-[0.9rem] font-semibold text-gray-600 truncate">
          {" "}
          {title}
        </p>
      </div>
      <div
        className="h-[200px] w-full flex justify-center items-center rounded-sm overflow-hidden my-1 cursor-pointer"
        style={{ background: "#808080" }}
      >
        {photoCount > 0 && path ? (
          <img
            src={path}
            alt="cover"
            className="h-full w-full object-cover"
            onClick={active}
          />
        ) : (
          <Camera size={44} strokeWidth={1.5} />
        )}
      </div>
      <div className="flex justify-between items-center border-t-2 border-gray-300 gap-2 pt-2">
        <p className="text-gray-500 font-bold">({photoCount})</p>
        <button
          type="button"
          className="px-2 py-1 flex gap-2 justify-center items-center bg-red-100 hover:bg-red-200 active:bg-red-300 transition-all rounded-sm w-[50%]"
          onClick={() => remove(i)}
        >
          <Trash2 size={20} color="#ef4444" />
          <p className="text-sm font-medium text-red-700">Delete</p>
        </button>
      </div>
    </div>
  );
};

function PhotoHome() {
  const [albumList, setAlbumList] = useState([]);
  const [activeAlbum, setActiveAlbum] = useState({
    name: "",
    Photos: [],
  });
  const [targetAlbum, setTargetAlbum] = useState("Move to Album");
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({
    name: "",
    des: "",
  });
  const [imgfile, uploadimg] = useState({});
  const [preView, setPreview] = useState({});
  const [preViewShow, setPreviewShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAlbum, setLoadingAlbum] = useState(false);
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const path = useLocation().pathname;
  const [previewImgSize, setPreviewImgSize] = useState({ width: 0, height: 0 });
  const [reachedEndOfList, setReachedEndOfList] = useState(false);
  // const [fetchingPhotos, setFetchingPhotos] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // const forceUpdate = useCallback(() => setActiveAlbum({...activeAlbum}), []);

  const LIMIT_PER_PAGE = 40;
  let currentPage = 1;

  const activeAlbumRef = useRef(null);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
    setActiveAlbum({ ...activeAlbum, name: "Unsorted" });
  }, []);

  useEffect(() => {
    if (org && user) {
      albumFirstPhoto();
      fetchPhotos(1);
    }
  }, [org]);

  const loadMorePhotos = () => {
    if (LIMIT_PER_PAGE >= totalCount) {
      setReachedEndOfList(true);
    } else {
      currentPage++;
      let offset = (currentPage - 1) * LIMIT_PER_PAGE + 1;
      fetchPhotos(offset);
      setReachedEndOfList(false);
    }
  };

  const options = {
    root: null,
    threshold: [0.75],
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        loadMorePhotos();
      }
    }, options);

    if (loadMore && loadMoreRef.current) {
      // Ensure loadMoreRef.current is not null
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [loadMore]);

  useEffect(() => {
    if (
      activeAlbum?.Photos?.length > 0 &&
      // !fetchingPhotos &&
      !reachedEndOfList &&
      activeAlbum?.name === "Unsorted"
    ) {
      setLoadMore(true);
    } else if (reachedEndOfList) {
      setLoadMore(false);
    }
  }, [activeAlbum, reachedEndOfList]);

  const handlePreviewImageLoad = (e) => {
    let { naturalWidth, naturalHeight } = e.target;

    const maxHeight = 600;
    const maxWidth = 800;

    // Maintain aspect ratio while adjusting dimensions
    if (naturalHeight > maxHeight) {
      const heightRatio = maxHeight / naturalHeight;
      naturalHeight = maxHeight;
      naturalWidth = naturalWidth * heightRatio;
    }

    if (naturalWidth > maxWidth) {
      const widthRatio = maxWidth / naturalWidth;
      naturalWidth = maxWidth;
      naturalHeight = naturalHeight * widthRatio;
    }

    setPreviewImgSize({ width: naturalWidth, height: naturalHeight });
  };

  const albumFirstPhoto = () => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/albumFirstPhoto/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("albumFirstPhoto response : ", res?.data?.data);
          setAlbumList(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const findAlbumById = async (id) => {
    setLoadingAlbum(true);
    let url = `${API_URL_CONSTANT.baseUrl}/albumdetailsById/${id}/${org}/${user}`;
    try {
      const res = await getReqParamheader(url);
      if (res?.status) {
        const albumData = res?.data?.data;
        console.log("albumData : ", albumData);
        setActiveAlbum({
          name: albumData?.Album_Name,
          Photos: albumData?.photoslist,
        });
        setLoadingAlbum(false);
      } else {
        console.log("response err", res?.message);
      }
    } catch (err) {
      console.log("err", err);
      toast.error(err?.response?.data?.message);
      setLoadingAlbum(false);
    }
  };

  const handleCreateAlbum = () => {
    setShowModal(false);
    let al = {};
    al.Album_Name = data?.name;
    al.Album_Description = data?.des;
    if (al?.Album_Name) {
      createAlbum(al);
    } else {
      alert("fill all required");
    }
  };
  const createAlbum = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/createPhotoalbum/${org}/${user}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('create album response', res?.data)
          albumFirstPhoto();
          setData({
            name: "",
            des: "",
          });
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleRemove = (id) => {
    let a = window.confirm("Are you sure you want to delete this album?");
    if (a) {
      if (id) {
        deletePhotoalbum(id);
      }
    }
  };

  const handleActive = async (albumId) => {
    if (activeAlbumRef.current) {
      activeAlbumRef.current.scrollIntoView({ behavior: "smooth" });
    }

    await findAlbumById(albumId);
  };

  const imgFilehandler = (e) => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];

      // Check if the file size is less than 2MB
      if (file.size > 2 * 1024 * 1024) {
        e.target.value = null;
        alert("File size should be less than 2MB.");
        return;
      }

      uploadimg({
        picturePreview: URL.createObjectURL(file),
        pictureAsFile: file,
      });
    }
  };

  const handleUpload = () => {
    setShow(!show);
  };
  const handleUploadSave = () => {
    setShow(false);
    if (user && org && imgfile) {
      let data = {};
      data.Photo_Title = title;
      data.files = imgfile?.pictureAsFile;
      uploadPhoto(data);
    }
  };
  const handleUploadPhotodirect = () => {
    setShow(false);
    let album_id = activeAlbum?.data?._id;
    if (user && org && imgfile && album_id) {
      let data = {};
      data.Photo_Title = title;
      data.files = imgfile?.pictureAsFile;
      uploadPhotodirect(data, album_id);
    }
  };
  const uploadPhotodirect = (data, id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotodirect/${id}/${org}/${user}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("upload photo in album response", res?.data);
          uploadimg({});
          setTitle("");
          fetchPhotos();
          albumFirstPhoto();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const handlePreview = (data) => {
    if (data._id != "999") {
      setPreview(data);
      setPreviewShow(!preViewShow);
    } else {
      alert("Can't preview/move logo");
    }
  };
  const handleRemovePhoto = (id) => {
    let a = window.confirm("Are you sure you want to delete this photo?");
    if (a) {
      setPreviewShow(false);
      if (id && activeAlbum?.name === "Unsorted") {
        removePhoto(id);
      } else {
        removePhotofromAlbum(id);
      }
    }
  };
  const fetchPhotos = (offset = 1) => {
    // setFetchingPhotos(true);
    let limit = LIMIT_PER_PAGE;
    let url = `${API_URL_CONSTANT.baseUrl}/unsortedPhotos/${org}/${user}?Limit=${limit}&Offset=${offset}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get all photos response", res?.data);
          let photosdata = res?.data?.data;
          setTotalCount(res?.data?.count);

          // Add the org logo to the photos data
          let orglogopath = getItem("currentOrgLogo");
          let orglogo = {
            _id: "999",
            tags: [],
            Photo_Path: orglogopath,
            PhotoAlbumId: "",
            orgId: org,
            deleted: false,
            deletedAt: null,
            deletedById: null,
            lastAccessedAt: {
              userId: "",
              time: "",
            },
            modifiedById: "",
            modifiedAt: "",
            createdAt: "",
            updatedAt: "",
            __v: 0,
          };
          photosdata.push(orglogo);

          // Create a Set to store unique Photo_Path
          const uniquePhotos = [];
          const photoPaths = new Set();

          photosdata.forEach((photo) => {
            if (!photoPaths.has(photo.Photo_Path)) {
              uniquePhotos.push(photo);
              photoPaths.add(photo.Photo_Path);
            }
          });

          if (uniquePhotos.length > 0) {
            setActiveAlbum((prevPhotos) => ({
              Photos: [...prevPhotos.Photos, ...uniquePhotos],
              name: "Unsorted",
            }));
          } else {
            setReachedEndOfList(true);
          }

          // setFetchingPhotos(false);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        // setFetchingPhotos(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const uploadPhoto = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotos/${org}/${user}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("upload photo response", res?.data);
          uploadimg({});
          setTitle("");
          fetchPhotos();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const removePhoto = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/deletePhotoById/${id}/${org}/${user}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete photo response", res?.data);
          fetchPhotos();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const removePhotofromAlbum = (pid) => {
    let url = `${API_URL_CONSTANT.baseUrl}/removephotofromAlbum/${activeAlbum?.data?._id}/${pid}/${org}/${user}`;
    console.log(url);
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete album  photo response", res?.data);
          fetchPhotos();
          albumFirstPhoto();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const deletePhotoalbum = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/deletePhotoalbum/${id}/${org}/${user}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete album response", res?.data);
          albumFirstPhoto();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleAddphotoToalbum = (photo_id, album_id) => {
    if (photo_id && album_id) {
      addphotoToalbum(album_id, photo_id);
    } else {
      alert("missing album id or photo id");
    }
  };
  const addphotoToalbum = (aid, pid) => {
    let url = `${API_URL_CONSTANT.baseUrl}/addphotoToalbum/${aid}/${pid}/${org}/${user}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("add photo to album response", res?.data);
          albumFirstPhoto();
          fetchPhotos();
          setPreviewShow(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6 divide-y-2 divide-gray-400">
          <div className="flex justify-between items-center mb-3  ">
            <p className="text-lg md:text-xl lg:text-2xl font-medium">Photos</p>
            <div className="flex gap-6 items-center">
              <CustomButton
                icon={<FolderPlus size={20} />}
                bgColor={"bg-blue-600"}
                content={"Create a New Album"}
                clickHandler={() => setShowModal(!showModal)}
                additionalStyles={"text-white hover:bg-blue-700"}
              />
              <CustomButton
                icon={<ImageUp size={20} />}
                bgColor={"bg-blue-600"}
                content={"Upload Photos"}
                clickHandler={handleUpload}
                additionalStyles={"text-white hover:bg-blue-700"}
              />
            </div>
          </div>

          <div className="w-full border-b pb-8">
            <p className="underline my-4 text-lg lg:text-xl font-medium">
              Album
            </p>

            {albumList.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {albumList.map((item, i) => (
                  <div key={i}>
                    <EmptyAlbum
                      i={item?._id}
                      title={item?.Album_Name}
                      photoCount={item?.Photos_Count}
                      path={item?.Recent_Photo || ""}
                      type="album"
                      remove={handleRemove}
                      active={() => handleActive(item?._id)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-[30%] w-full ">
                <p className="text-lg font-medium text-gray-500">
                  No Album Found
                </p>
              </div>
            )}
          </div>

          <div className="my-4 " id="activeAlbum" ref={activeAlbumRef}>
            <div className="w-full flex justify-between items-center">
              <p className="underline my-4 text-lg lg:text-xl font-medium">
                {loadingAlbum ? "Loading album" : `${activeAlbum?.name} Photos`}{" "}
              </p>
              {activeAlbum?.name !== "Unsorted" && (
                <CustomButton
                  bgColor={"bg-blue-600"}
                  content={"View Unsorted"}
                  clickHandler={() => {
                    setActiveAlbum({ ...activeAlbum, name: "Unsorted" });
                    fetchPhotos();
                  }}
                  additionalStyles={"text-white hover:bg-blue-700"}
                />
              )}
            </div>
            {loadingAlbum ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2 h-[300px] overflow-hidden">
                {Array.from({ length: 4 }).map((_, i) => (
                  <div
                    key={i}
                    className="p-2 rounded-md shadow-lg bg-white transition-all animate-pulse "
                  >
                    <div className="h-[250px] w-full flex justify-center items-center rounded-sm bg-gray-400">
                      <Image size={60} color="#252525" strokeWidth={1.5} />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {activeAlbum?.Photos?.map((item, i) => (
                  <div
                    key={i}
                    className="p-2 rounded-md shadow-lg bg-white flex flex-col gap-1 hover:scale-105 hover:shadow-2xl transition-all"
                  >
                    <img
                      className="h-[250px] w-full cursor-pointer object-cover rounded-sm"
                      src={item?.Photo_Path}
                      alt="photo path missing"
                      loading="lazy"
                      onClick={() => handlePreview(item)}
                    />
                    <p className="font-semibold text-sm text-gray-500">
                      Uploaded on{" "}
                      <Moment format="DD-MMM-YYYY">{item?.createdAt}</Moment>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {showModal && (
            <Modal setOpenModal={setShowModal} title="Create Photo Album">
              <label className="text-gray-600"> Album Name :</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <label className="text-gray-600">
                {" "}
                Album Description (Optional):
              </label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.des}
                onChange={(e) => setData({ ...data, des: e.target.value })}
              />
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 bg-blue-500 rounded-sm my-3  text-white hover:bg-blue-700"
                  onClick={() => handleCreateAlbum()}
                >
                  Create Photo Album
                </button>
              </div>
            </Modal>
          )}
          {show && (
            <Modal setOpenModal={setShow} title="Upload Photo">
              <label className="text-gray-600"> Select Photo :</label>
              {imgfile?.picturePreview ? (
                <div className="max-h-[55vh] w-full flex justify-center flex-col overflow-hidden">
                  <img
                    src={imgfile?.picturePreview}
                    onLoad={handlePreviewImageLoad}
                    alt="photo"
                    className={`h-[${previewImgSize.height}px] w-[${previewImgSize.width}px] object-cover`}
                  />
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    className="px-4 py-1 my-2 w-full border"
                    accept=".jpg,.jpeg"
                    onChange={imgFilehandler}
                  />
                  <p className="text-xs text-gray-600 font-semibold ps-4">
                    Photo size should be less than 2MB
                  </p>
                </>
              )}

              <div className="flex mt-4 justify-between items-center">
                {imgfile?.picturePreview && (
                  <CustomButton
                    icon={<Trash2 size={20} />}
                    bgColor={"bg-red-600"}
                    content={"Remove"}
                    clickHandler={() => uploadimg({})}
                    additionalStyles={"text-white hover:bg-red-700"}
                  />
                )}

                <CustomButton
                  icon={<Save size={20} />}
                  bgColor={"bg-blue-600"}
                  content={"Save Photo"}
                  clickHandler={
                    activeAlbum?.name !== "Unsorted"
                      ? handleUploadPhotodirect
                      : handleUploadSave
                  }
                  additionalStyles={"text-white hover:bg-blue-700 self-end"}
                  disabled={!imgfile?.picturePreview}
                />
              </div>
            </Modal>
          )}
          {preViewShow && (
            <Modal setOpenModal={setPreviewShow} title="Preview Photo">
              {preView?.Photo_Path ? (
                <div className="max-h-[60vh] w-full flex justify-center flex-col mt-3 overflow-y-auto">
                  <img
                    onLoad={handlePreviewImageLoad}
                    src={preView?.Photo_Path}
                    alt="photo"
                    className={`h-[${previewImgSize.height}px] w-[${previewImgSize.width}px] object-cover`}
                  />
                </div>
              ) : (
                <div className="w-full flex justify-center flex-col mt-3">
                  <img
                    src={preView?.Photo_Path}
                    alt="photo"
                    className="h-[150px] w-[150px]"
                  />
                </div>
              )}
              <div className="border-b pb-2 mt-4 h-[12px] w-full"></div>

              <div className="flex justify-between items-center mt-4">
                <div>
                  <select
                    className="border border-gray-300 text-gray-700 text-sm rounded-sm px-4 py-[7px] bg-white"
                    onChange={(e) => {
                      setTargetAlbum(e.target.value);
                    }}
                    defaultValue=""
                  >
                    <option value="" disabled hidden>
                      Move to Album
                    </option>
                    {albumList?.length > 0 ? (
                      <>
                        {albumList.map((item, i) => (
                          <option value={item?._id} key={i}>
                            {item?.Album_Name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="" disabled>
                        No albums available
                      </option>
                    )}
                  </select>
                  {targetAlbum !== "Move to Album" && (
                    <button
                      className="ms-4 transition-all px-4 py-[7px] bg-blue-600 rounded-sm ml-3  text-white hover:bg-blue-700"
                      onClick={() =>
                        handleAddphotoToalbum(preView?._id, targetAlbum)
                      }
                    >
                      Move
                    </button>
                  )}
                </div>
                <button
                  className="px-4 py-[7px] bg-red-600 rounded-sm ml-3  text-white hover:bg-red-700"
                  onClick={() =>
                    handleRemovePhoto(
                      activeAlbum?.name !== "Unsorted"
                        ? preView?._id
                        : preView?._id
                    )
                  }
                >
                  Remove
                </button>
              </div>
            </Modal>
          )}
          <ToastContainer />
          {loadMore &&
            totalCount > LIMIT_PER_PAGE &&
            activeAlbum?.name === "Unsorted" && (
              <div
                ref={loadMoreRef}
                className="flex w-full justify-center items-center h-32 loadingtext"
              >
                <p>Loading</p>
              </div>
            )}
        </div>
      )}
    </>
  );
}

export default PhotoHome;
