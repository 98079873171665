import React, { useState, useEffect, useContext } from "react";
import { getItem } from "../../utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { getReqParamheader } from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import UpdateOrg from "../UpdateOrg";
import PageSnipper from "../../components/PageSnipper";
import { OrgContext } from "../../OrgContext";
import Moment from "react-moment";
import { formatDateInPDT } from "../../utils/helper";
import CustomButton from "../../components/CustomButton";
import { MailPlus, CirclePlus, Pencil } from "lucide-react";

function Dashboard() {
  const [people, setPeople] = useState([]);
  const [article, setArticle] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [userId, setUserId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [org, setOrg] = useState("");
  const [orgData, setOrgData] = useState({});
  const [isDashbord, setIsDashboard] = useState(true);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { organizationId } = useContext(OrgContext);

  var dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    let orgname = getItem("currentOrg");
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    if (orgname && userid && orgid) {
      setOrg(orgname);
      setUserId(userid);
      setOrgId(orgid);
    }
  }, []);

  useEffect(() => {
    if (orgId) {
      setLoading(true);
      getArticleData(orgId);
      getContact();
      getCurrentOrg(orgId);
      getEmailStatus();
    }
  }, [orgId]);

  useEffect(() => {
    if (organizationId) {
      setOrgId(organizationId);
      let orgname = getItem("currentOrg");
      if (orgname) {
        setOrg(orgname);
      }
      // setLoading(true)
      // getArticleData(orgId)
      // getContact()
      // getCurrentOrg(orgId)
      // getEmailStatus()
    }
  }, [organizationId]);

  const handleViewContact = () => {
    navigate(`${path}/contact`);
  };
  const handleViewArticle = () => {
    navigate(`${path}/article`);
  };
  const createArticle = () => {
    navigate(`${path}/article/create`);
  };
  const createEmail = () => {
    navigate(`${path}/email/create`);
  };
  const handleViewEmail = () => {
    navigate(`${path}/email`);
  };

  const getArticleData = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/recent_article_details/${id}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get article list response", res?.data);
          let filter = res?.data?.data?.filter(
            (item) => item?.Document_status === "Published"
          );
          if (filter?.length > 3) {
            setArticle(filter.slice(0, 3));
          } else {
            setArticle(filter);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getContact = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/recent_contact_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get contact details response", res?.data);
          let data = res?.data?.data;
          if (data?.length > 3) {
            setPeople(data.slice(0, 3));
          } else {
            setPeople(data);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getCurrentOrg = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Organization_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get org dedtails response", res?.data);
          setOrgData(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getEmailStatus = () => {
    getRecentEmailList();
  };

  const getRecentEmailList = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/recent_email_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get email list response", res?.data);
          let filter = res?.data?.data?.filter(
            (item) => item?.Email_status === "Sent"
          );
          if (filter?.length > 3) {
            setEmailData(filter.slice(0, 3));
          } else {
            setEmailData(filter);
          }
          setLoading(false);
        } else {
          setLoading(false);
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const handleEditOrg = () => {
    setIsDashboard(!isDashbord ? isDashbord : !isDashbord);
  };

  return loading ? (
    <PageSnipper loading={loading} />
  ) : (
    <div className="px-6">
      <div className="flex justify-between items-center mb-6">
        <p
          className={`
            ${
              isDashbord
                ? "text-blue-500"
                : "cursor-pointer hover:text-blue-500"
            } text-lg lg:text-2xl font-medium`}
          onClick={() => setIsDashboard(isDashbord ? isDashbord : !isDashbord)}
        >
          {org} Dashboard <br />
          {!isDashbord && (
            <div style={{ fontSize: "12px" }}> &#x25c0; Back to Dashboard</div>
          )}
        </p>
        <CustomButton
          icon={<Pencil size={20} />}
          bgColor={"bg-blue-600"}
          content={"Edit Org Profile"}
          clickHandler={handleEditOrg}
          additionalStyles={"text-white hover:bg-blue-700"}
        />
      </div>
      {isDashbord ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
          <div className="border rounded-md bg-white">
            <div className="px-4 py-3 border-b flex justify-between items-center">
              <div className="text-base md:text-xl font-medium">New People</div>
              <div className="flex gap-4">
                <CustomButton
                  bgColor={"bg-blue-50"}
                  content={"View All"}
                  clickHandler={handleViewContact}
                  additionalStyles={
                    "text-blue-500 hover:bg-blue-200 hover:outline-2 hover:outline-blue-500"
                  }
                />
              </div>
            </div>

            <div className="px-4 py-1 divide-y-2">
              {people?.length > 0 ? (
                <>
                  {people.map((item, i) => (
                    <div key={i} className="py-2.5">
                      <p className="truncate font-medium text-gray-800">
                        {item?.Email}{" "}
                      </p>

                      <p className="text-sm font-semibold text-gray-600">
                        Added at :{" "}
                        <Moment format="DD-MMM-YYYY">{item?.createdAt}</Moment>
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-gray-600"> No Contact Found </p>
              )}
            </div>
          </div>
          <div className="border rounded-md bg-white">
            <div className="px-4 py-3 border-b flex justify-between items-center">
              <div className="text-base md:text-xl font-medium">
                Recent Articles
              </div>
              <div className="flex gap-2">
                <CustomButton
                  bgColor={"bg-blue-50"}
                  content={"View All"}
                  clickHandler={handleViewArticle}
                  additionalStyles={
                    "text-blue-500 hover:bg-blue-100 hover:outline-blue-500"
                  }
                />
                <CustomButton
                  icon={<CirclePlus size={20} />}
                  bgColor={"bg-blue-600"}
                  content={"Create New"}
                  clickHandler={createArticle}
                  additionalStyles={"text-white hover:bg-blue-700"}
                />
              </div>
            </div>

            <div className="px-4 py-1 divide-y-2">
              {article?.length > 0 ? (
                <>
                  {article.map((item, i) => (
                    <div key={i} className="py-2.5">
                      <p className="truncate font-medium  text-gray-800">
                        {item?.draft?.Title}{" "}
                      </p>
                      <span className="text-gray-600 font-semibold text-sm">
                        Published at:&nbsp;
                        <Moment format="DD-MMM-YYYY">
                          {item?.lastAccessedAt?.time}
                        </Moment>
                      </span>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-gray-500 text-sm">
                  {" "}
                  You have no published articles. Try creating and publishing
                  one!{" "}
                </p>
              )}
            </div>
          </div>
          <div className="border rounded-md bg-white">
            <div className="px-4 py-3 border-b-2 flex justify-between items-center">
              <div className="text-base md:text-xl font-semibold">
                Recent Emails
              </div>
              <div className="flex gap-2">
                <CustomButton
                  bgColor={"bg-blue-50"}
                  content={"View All"}
                  clickHandler={handleViewEmail}
                  additionalStyles={"text-blue-500 hover:bg-blue-100"}
                />
                <CustomButton
                  icon={<MailPlus size={20} />}
                  bgColor={"bg-blue-600"}
                  content={"Create New"}
                  clickHandler={createEmail}
                  additionalStyles={"text-white hover:bg-blue-700"}
                />
              </div>
            </div>

            <div className="px-4 py-1 divide-y-2">
              {emailData?.length > 0 ? (
                <>
                  {emailData.map((item, i) => (
                    <div key={i} className="py-2.5 ">
                      <p className="truncate font-medium text-gray-800">
                        {item?.Subject}{" "}
                      </p>
                      <p className="text-sm font-semibold text-gray-600">
                        Sent at :&nbsp;
                        {formatDateInPDT(item?.lastAccessedAt?.time, true)}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-gray-500 text-sm">
                  {" "}
                  You have not sent an email yet. Try creating and sending one !{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <div className="w-full">
            <UpdateOrg
              orgId={orgId}
              data={orgData}
              setIsDashboard={setIsDashboard}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
